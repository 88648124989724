// Third party.
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Box bgcolor="background.light">
      <Typography
        variant="h6"
        component="p"
        style={{ fontWeight:900 }}
      >
        Stanford Studies
      </Typography>
    </Box>
  );
}

export default Home;