import {
  PAGE_TITLES,
  P_HOME,
  P_GO,
  P_GOTO,
} from 'misc/appConstants';
import Home from 'pages/home';
import Go from 'pages/go';
import Goto from 'pages/goto';
// Third party.
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { blue } from '@mui/material/colors';

const theme = createTheme({
  typography: {
    //fontFamily: ['"Mulish"', 'sans-serif'].join(','),
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      light: blue[300],
      main: '#3c8cff',
      dark: blue[800],
    },
    background: {
      light: '#f7f9fd',
      main: '#292b42',
      dark: '#2D3142', //'#14162a',
    }
  },
});

const responsiveTheme = responsiveFontSizes(theme);

function App() {
 document.title = PAGE_TITLES[P_HOME];

  return (
    <ThemeProvider theme={responsiveTheme}>
      <BrowserRouter>
        <Routes>
          <Route path={P_GOTO} element={<Goto />} />
          <Route path={P_GO} element={<Go />} />
          <Route path={P_HOME} element={<Home />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
