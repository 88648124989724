import {
  msToHumanReadableTime
} from 'misc/appConstants';
// Third party.
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

async function getFingerPrintId() {
  // Initialize an agent at application startup.
  const fpPromise = FingerprintJS.load();

  // Get the visitor identifier when you need it.
  const fp = await fpPromise;
  const result = await fp.get();
  const fingerPrintId = result.visitorId;;

  return fingerPrintId;
}

async function getIp() {
  const ip = await axios.get('https://api.ipify.org?format=json')
  .then((res) => {
    const ip = res?.data?.ip;
    return ip;
  });

  return ip;
}

function sendMessage(targetLink, title, msg) {
  const API_VERSION = '1';

  const appAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + API_VERSION + '/',
    timeout: 20000,
  });

  const post = (
    url,
    params,
    config,
  ) => {
    return new Promise((resolve, reject) => {
      appAxios
        .post(url, params, { ...config })
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  };

  const sendLarkBotMessage = (
    title,
    message = '',
    attr,
  ) => {
    return post('/app/send_notification', {
      env: process.env.REACT_APP_ENV,
      title,
      message,
      ...attr,
    })
    .then(() => {
      window.location.replace(targetLink);
    })
    .catch();
  };

  sendLarkBotMessage(
    title,
    msg,
    {
      uuid: '4cd8646c-0379-4bbb-99e1-37cb1aab0588',
    },
  );
}

function Go() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Compose params.
    const params = {};
    for (let key of searchParams.keys()) {
      params[key] = searchParams.get(key);
    }
    const targetLink = params['url'];
    const targetEmail = params['email'];
    const currentTimeInMs = Date.now();
    const msgObject = {
      ...params,
      currentTimeInMs,
    };

    // Send bot message.
    if (targetLink) {
      Promise.all([getIp(), getFingerPrintId()])
      .then((values) => {
        const ip = values[0];
        const fingerPrintId = values[1];

        if (ip) {
          msgObject['ip'] = ip;
        }
        if (fingerPrintId) {
          msgObject['fingerPrintId'] = fingerPrintId;
        }

        let msg = "";
        Object.keys(msgObject).forEach((key) => {
          if (msg) {
            msg += "\n";
          }
          msg += key + ": " + msgObject[key];
        });
        if (msgObject['timeInMs'] && msgObject['currentTimeInMs']) {
          if (msg) {
            msg += "\n";
          }
          const delayInMs = msgObject['currentTimeInMs'] - msgObject['timeInMs'];
          msg += "delay : " + msToHumanReadableTime(delayInMs);
        }
        const title = targetEmail
            ? (targetEmail + " clicked link")
            : "Clicked link";
        sendMessage(targetLink, title, msg);
      })
      .catch();
    }
  }, [searchParams]);

  return null;
}

export default Go;