export const APP_NAME = 'Stanford Studies';

// Route paths.
export const P_HOME = '/';
export const P_GO = '/go';
export const P_GOTO = '/goto';

// Page titles.
// Helper function to generate page titles.
function _pageTitle(title) {
  if (!title) {
    return APP_NAME + " - Research";
  }
  return title + " - " + APP_NAME;
}

export const PAGE_TITLES = {
  [P_HOME]: _pageTitle(),
  [P_GO]: _pageTitle(),
};

export const msToHumanReadableTime = (ms) => {
  const seconds = (ms / 1000).toFixed(1);
  const minutes = (ms / (1000 * 60)).toFixed(1);
  const hours = (ms / (1000 * 60 * 60)).toFixed(1);
  const days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
  if (seconds < 60) return seconds + " seconds";
  else if (minutes < 60) return minutes + " minutes";
  else if (hours < 24) return hours + " hours";
  else return days + " days"
};
